import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showPathwayModal: false,
    subx_id: "",
    isShowSideNav: false,
    applyNow: "",
    subx_id_error: false,
    partner_selected: "",
    rfi_subx_id: "",
    CheckingPartnerNumber: false,
    rfi_CheckingPartnerNumber: false,
    rfi_subx_id_error: false,
    rfi_partner_selected: "yes",
    isPartnerNumberValid: true,
    rfi_isPartnerNumberValid: true,
    layout: "default-layout",
    openHouse: {
      day: "December 05, 2024",
      time: "12:00 PM to 01:00 PM MST",
      link:
        "https://asu.zoom.us/webinar/register/9617255712817/WN_vKNZ8G6_QmKxs9VsOvnRrQ#/registration",
    },
    checked: true,
    degreeselected: false,
    animationStarted: null,
    orientationChanged: false,
  },
  mutations: {
    SET_LAYOUT(state, payload) {
      state.layout = payload;
    },
    UPDATE_RFI_SUBX_ID(state, payload) {
      state.rfi_subx_id = payload;
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },
  },
  actions: {},
});
